import type { Brand } from 'src/interfaces/brand';
import type { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '@reduxjs/toolkit';

export type UserState = {
  loading: boolean;
  error: string | null;
  selectedBrand: Brand | null;
};

const initialState: UserState = {
  loading: false,
  error: null,
  selectedBrand: null,
};

export const userSlice = createSlice({
  name: 'selected-brand',
  initialState,
  reducers: {
    setSelectedBrand: (state, action: PayloadAction<Brand | null>) => {
      state.selectedBrand = action.payload;
    },
    updateSelectedBrand: (state, action: PayloadAction<Partial<Brand>>) => {
      if (state.selectedBrand) {
        state.selectedBrand = { ...state.selectedBrand, ...action.payload };
      }
    },
  },
});

export const { setSelectedBrand, updateSelectedBrand } = userSlice.actions;

export default userSlice.reducer;
