import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthSplitLayout } from 'src/layouts/auth-split';
import { AuthProfileLayout } from 'src/layouts/auth-split/profile-layout';

import { SplashScreen } from 'src/components/loading-screen';
// ----------------------------------------------------------------------

const Firebase = {
  SignInPage: lazy(() => import('src/pages/auth/sign-in')),
  SignUpPage: lazy(() => import('src/pages/auth/sign-up')),
  FinishProfile: lazy(() => import('src/pages/auth/finish-profile')),
  VerifyPage: lazy(() => import('src/pages/auth/verify')),
  ResetPasswordPage: lazy(() => import('src/pages/auth/reset-password')),
  BrandTopic: lazy(() => import('src/pages/auth/brand-topics')),
  CreateSubscription: lazy(() => import('src/pages/auth/create-subscription')),
};

const authFirebase = {
  children: [
    {
      path: 'sign-in',
      element: (
        <AuthSplitLayout>
          <Firebase.SignInPage />
        </AuthSplitLayout>
      ),
    },
    {
      path: 'sign-up',
      element: (
        <AuthSplitLayout>
          <Firebase.SignUpPage />
        </AuthSplitLayout>
      ),
    },
    {
      path: 'finish-profile',
      element: (
        <AuthProfileLayout>
          <Firebase.FinishProfile />
        </AuthProfileLayout>
      ),
    },
    {
      path: 'brand-topics',
      element: (
        <AuthProfileLayout>
          <Firebase.BrandTopic />
        </AuthProfileLayout>
      ),
    },
    {
      path: 'verify',
      element: (
        <AuthSplitLayout>
          <Firebase.VerifyPage />
        </AuthSplitLayout>
      ),
    },
    {
      path: 'reset-password',
      element: (
        <AuthSplitLayout>
          <Firebase.ResetPasswordPage />
        </AuthSplitLayout>
      ),
    },
    {
      path: 'create-subscription',
      element: (
        <AuthSplitLayout>
          <Firebase.CreateSubscription />
        </AuthSplitLayout>
      ),
    },
  ],
};

// ----------------------------------------------------------------------

export const authRoutes = [
  {
    path: 'auth',
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [authFirebase],
  },
];
