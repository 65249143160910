import type { User } from 'src/interfaces/user';
import type { StackProps } from '@mui/material/Stack';

import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import { _mock } from 'src/_mock';

// ----------------------------------------------------------------------

export function NavUpgrade({ sx, ...other }: StackProps) {
  const user = useSelector((state: { user: { user: User | null } }) => state.user.user);

  return (
    <Stack sx={{ px: 2, py: 5, textAlign: 'center', ...sx }} {...other}>
      <Stack alignItems="center">
        <Box sx={{ position: 'relative' }}>
          <Avatar
            src={_mock.image.avatar(24)}
            alt={user?.display_name || ''}
            sx={{ width: 48, height: 48 }}
          >
            {user?.display_name?.charAt(0).toUpperCase() || ''}
          </Avatar>
        </Box>

        <Stack spacing={0.5} sx={{ mb: 2, mt: 1.5, width: 1 }}>
          <Typography
            variant="subtitle2"
            noWrap
            sx={{ color: 'var(--layout-nav-text-primary-color)' }}
          >
            We&apos;re here to help
          </Typography>

          <Typography
            variant="body2"
            noWrap
            sx={{ color: 'var(--layout-nav-text-disabled-color)' }}
          >
            brands@postedapp.com
          </Typography>
        </Stack>

        <Button
          variant="contained"
          href="https://calendly.com/steven-postedapp/posted-app-support"
          target="_blank"
          rel="noopener"
        >
          Book a Call
        </Button>
      </Stack>
    </Stack>
  );
}
