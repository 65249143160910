import type { PayloadAction } from '@reduxjs/toolkit';

import axios from 'axios';
import { put, call, takeLatest } from 'redux-saga/effects';

import { getFirebaseToken } from './changeToReadySaga';
import {
  triggerCreateLeadRequest,
  triggerCreateLeadSuccess,
  triggerCreateLeadFailure,
} from '../reducers/createLeadSlice';

function* createLeadSaga(action: PayloadAction): Generator<any, void, any> {
  try {
    const idToken = yield call(getFirebaseToken);

    yield call(axios.post, `${import.meta.env.VITE_BASE_URL}/admin/create-lead`, action.payload, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
    });

    yield put(triggerCreateLeadSuccess());
  } catch (error: any) {
    yield put(triggerCreateLeadFailure(error.message || 'Failed to create lead'));
  }
}

export function* watchCreateLeadSaga() {
  yield takeLatest(triggerCreateLeadRequest.type, createLeadSaga);
}
