import type { User } from 'src/interfaces/user';
import type { RootState } from 'src/store/store';

import { useDispatch, useSelector } from 'react-redux';

import { setUser, updateUser, triggerFetchUserRequest } from 'src/store/reducers/userSlice';

export const useUser = () => {
  const dispatch = useDispatch();
  const { user, loading, error } = useSelector((state: RootState) => state.user);

  const setUserAction = (newUser: User | null) => {
    dispatch(setUser(newUser));
  };

  const updateUserAction = (updatedFields: Partial<User>) => {
    dispatch(updateUser(updatedFields));
  };

  const fetchUser = (userId: string) => {
    dispatch(triggerFetchUserRequest(userId));
  };

  return {
    user,
    loading,
    error,
    setUser: setUserAction,
    updateUser: updateUserAction,
    fetchUser,
  };
};
