import type { Breakpoint } from '@mui/material/styles';

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { varAlpha } from 'src/theme/styles';

import { Logo } from 'src/components/logo';
import { type NavSectionProps } from 'src/components/nav-section';
import { ScrollbarRegistration } from 'src/components/scrollbar/scrollbarRegistartion';

import { NavUpgrade } from '../nav-upgrade';

// ----------------------------------------------------------------------

export type NavVerticalFinishProfileProps = NavSectionProps & {
  isNavMini: boolean;
  layoutQuery: Breakpoint;
  onToggleNav: () => void;
  slots?: {
    topArea?: React.ReactNode;
    bottomArea?: React.ReactNode;
  };
};

export function NavVerticalFinishProfile({
  sx,
  data,
  slots,
  isNavMini,
  layoutQuery,
  onToggleNav,
  ...other
}: NavVerticalFinishProfileProps) {
  const theme = useTheme();

  const renderNavVertical = (
    <>
      <Box sx={{ pl: 3.5, pt: 2.5, pb: 1 }}>
        <Logo href="/auth/sign-in" />
      </Box>

      <ScrollbarRegistration fillContent sx={{ flexGrow: 1 }} />

      <NavUpgrade sx={{ mt: 'auto' }} />
    </>
  );

  return (
    <Box
      sx={{
        top: 0,
        left: 0,
        height: 1,
        display: 'none',
        position: 'fixed',
        flexDirection: 'column',
        bgcolor: 'var(--layout-nav-bg)',
        zIndex: 'var(--layout-nav-zIndex)',
        width: isNavMini ? 'var(--layout-nav-mini-width)' : 'var(--layout-nav-vertical-width)',
        borderRight: `1px solid var(--layout-nav-border-color, ${varAlpha(theme.vars.palette.grey['500Channel'], 0.12)})`,
        transition: theme.transitions.create(['width'], {
          easing: 'var(--layout-transition-easing)',
          duration: 'var(--layout-transition-duration)',
        }),
        [theme.breakpoints.up(layoutQuery)]: {
          display: 'flex',
        },
        ...sx,
      }}
    >
      {renderNavVertical}
    </Box>
  );
}
