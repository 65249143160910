import type { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '@reduxjs/toolkit';

export type ContestState = {
  loading: boolean;
  error: string | null;
};

const initialState: ContestState = {
  loading: false,
  error: null,
};

export const contestSlice = createSlice({
  name: 'trigger-contest',
  initialState,
  reducers: {
    triggerStartContestRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    triggerStartContestSuccess: (state) => {
      state.loading = false;
    },
    triggerStartContestFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  triggerStartContestRequest,
  triggerStartContestSuccess,
  triggerStartContestFailure,
} = contestSlice.actions;

export default contestSlice.reducer;
