import axios from 'axios';
import { put, call, takeLatest } from 'redux-saga/effects';

import { getFirebaseToken } from './changeToReadySaga';
import { paymentRequest, paymentFailure, paymentSuccess } from '../reducers/paymentSlice';

function* paymentSaga(action: ReturnType<typeof paymentRequest>): Generator<any, void, any> {
  const idToken = yield call(getFirebaseToken);
  try {
    const response = yield call(
      axios.post,
      `${import.meta.env.VITE_BASE_URL}/payment/create-checkout-session`,
      {
        amount: action.payload.amount,
        contestId: action.payload.contestId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    if (response.data.url) {
      window.location.href = response.data.url;
    } else {
      throw new Error('No checkout URL provided');
    }

    yield put(paymentSuccess(response.data));
  } catch (error: any) {
    yield put(paymentFailure(error.message || 'Failed to make payment'));
  }
}

export function* watchPaymentSaga() {
  yield takeLatest(paymentRequest, paymentSaga);
}
