import type { ReactNode } from 'react';

import { lazy, Suspense, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { Outlet, Navigate } from 'react-router-dom';

import { useUser } from 'src/hooks/use-user';

import { AUTH } from 'src/lib/firebase';
import { DashboardLayout } from 'src/layouts/dashboard';

import { LoadingScreen } from 'src/components/loading-screen';

import { AuthGuard, RoleBasedGuard } from 'src/auth/guard';

const ContestsPage = lazy(() => import('src/pages/dashboard/contests'));
const MyBrandPage = lazy(() => import('src/pages/dashboard/my-brand'));
const AccountSettingsPage = lazy(() => import('src/pages/dashboard/account-settings'));
const PayoutsPage = lazy(() => import('src/pages/dashboard/payouts'));
const UserManagementPage = lazy(() => import('src/pages/super-admin/user-management'));
const ContestDetailsPage = lazy(() => import('src/pages/dashboard/contests/contest-details'));
const CreateContestView = lazy(() => import('src/pages/dashboard/contests/create-new-contest'));
const ViewAllContests = lazy(() => import('src/pages/super-admin/all-contests'));
const EditUserPage = lazy(() => import('src/pages/super-admin/edit-user'));
const BrandPage = lazy(() => import('src/pages/super-admin/brands'));
const BrandManagementPage = lazy(() => import('src/pages/super-admin/brand-management'));

const DashboardRoutesWrapper = () => {
  const { user, fetchUser } = useUser();

  useEffect(() => {
    if (!user) {
      const unsubscribe = onAuthStateChanged(AUTH, (userProfile) => {
        if (userProfile) {
          fetchUser(userProfile.uid);
        } else {
          console.error('User is not logged in');
        }
      });

      return () => unsubscribe();
    }

    return () => {};
  }, [fetchUser, user]);

  const userRole = user?.role || '';

  return (
    <DashboardLayout>
      <Suspense fallback={<LoadingScreen />}>
        <Outlet context={{ userRole }} />
      </Suspense>
    </DashboardLayout>
  );
};

interface ProtectedRouteProps {
  acceptRoles: string[];
  children: ReactNode;
}

const ProtectedRoute = ({ acceptRoles, children }: ProtectedRouteProps) => {
  const { user } = useUser();
  const userRole = user?.role || '';

  return (
    <RoleBasedGuard acceptRoles={acceptRoles} currentRole={userRole}>
      {children}
    </RoleBasedGuard>
  );
};

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardRoutesWrapper />
      </AuthGuard>
    ),
    children: [
      {
        path: 'payouts',
        element: (
          <ProtectedRoute acceptRoles={['Superadmin']}>
            <PayoutsPage />
          </ProtectedRoute>
        ),
        index: true,
      },
      {
        path: 'contests',
        element: (
          <ProtectedRoute acceptRoles={['Brand Admin']}>
            <ContestsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'contest/:contestId',
        element: (
          <ProtectedRoute acceptRoles={['Brand Admin', 'Superadmin']}>
            <ContestDetailsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'contest/create-new-contest',
        element: (
          <ProtectedRoute acceptRoles={['Brand Admin', 'Superadmin']}>
            <CreateContestView />
          </ProtectedRoute>
        ),
      },
      {
        path: 'contest/view-all-contests',
        element: (
          <ProtectedRoute acceptRoles={['Superadmin']}>
            <ViewAllContests />
          </ProtectedRoute>
        ),
      },
      {
        path: 'my-brand',
        element: (
          <ProtectedRoute acceptRoles={['Brand Admin']}>
            <MyBrandPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'brands',
        element: (
          <ProtectedRoute acceptRoles={['Superadmin']}>
            <BrandPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'settings',
        element: <AccountSettingsPage />,
      },
      {
        path: 'user-management',
        element: (
          <ProtectedRoute acceptRoles={['Superadmin']}>
            <UserManagementPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'user-management/:userId',
        element: (
          <ProtectedRoute acceptRoles={['Superadmin']}>
            <EditUserPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'brand-management/:brandId',
        element: (
          <ProtectedRoute acceptRoles={['Superadmin']}>
            <BrandManagementPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/dashboard/contests/success',
        element: <Navigate to="/dashboard/contests" />,
      },
      {
        path: '/dashboard/contests/cancel',
        element: <Navigate to="/dashboard/contests" />,
      },
      {
        path: '/dashboard/contests/success?checkout=subscription',
        element: <Navigate to="/dashboard/contests" />,
      },
      {
        path: '/dashboard/contests/success?checkout=contest',
        element: <Navigate to="/dashboard/contests" />,
      },
    ],
  },
];
