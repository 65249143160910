import type { Theme, SxProps, Breakpoint } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { useTheme } from '@mui/material/styles';

import { paths } from 'src/routes/paths';

import { useUser } from 'src/hooks/use-user';

import { Logo } from 'src/components/logo';
import { useSettingsContext } from 'src/components/settings';

import { Main, Content } from './main';
import { layoutClasses } from '../classes';
import { _account } from '../config-nav-account';
import { navData } from '../config-nav-dashboard';
import { useNavColorVars } from '../dashboard/styles';
import { HeaderSection } from '../core/header-section';
import { LayoutSection } from '../core/layout-section';
import { AccountDrawer } from '../components/account-drawer';
import { NavVerticalFinishProfile } from '../components/registartion/nav-vertical';

// ----------------------------------------------------------------------

export type AuthSplitLayoutProps = {
  sx?: SxProps<Theme>;
  children: React.ReactNode;
  header?: {
    sx?: SxProps<Theme>;
  };
};

export function AuthProfileLayout({ sx, children, header }: AuthSplitLayoutProps) {
  const theme = useTheme();
  const settings = useSettingsContext();
  const layoutQuery: Breakpoint = 'md';
  const { user } = useUser();

  const navColorVars = useNavColorVars(theme, settings);
  const userRole =
    user?.role === 'Superadmin' ? paths.dashboard.viewAllContests : paths.dashboard.root;

  const isNavMini = settings.navLayout === 'mini';

  return (
    <LayoutSection
      headerSection={
        /** **************************************
         * Header
         *************************************** */
        <HeaderSection
          disableElevation
          layoutQuery={layoutQuery}
          slotProps={{ container: { maxWidth: false } }}
          sx={{
            position: { [layoutQuery]: 'fixed' },
            backgroundColor: theme.palette.background.default,
            ...header?.sx,
          }}
          slots={{
            topArea: (
              <Alert severity="info" sx={{ display: 'none', borderRadius: 0 }}>
                This is an info Alert.
              </Alert>
            ),
            leftArea: <Logo href={userRole} />,
            rightArea: (
              <Box display="flex" alignItems="center" gap={{ xs: 1, sm: 1.5 }}>
                <AccountDrawer data={_account} />
              </Box>
            ),
          }}
        />
      }
      /** **************************************
       * Sidebar
       *************************************** */
      sidebarSection={
        <NavVerticalFinishProfile
          data={navData}
          isNavMini={false}
          layoutQuery={layoutQuery}
          cssVars={navColorVars.section}
          onToggleNav={() =>
            settings.onUpdateField(
              'navLayout',
              settings.navLayout === 'vertical' ? 'mini' : 'vertical'
            )
          }
        />
      }
      /** **************************************
       * Footer
       *************************************** */
      footerSection={null}
      /** **************************************
       * Style
       *************************************** */
      cssVars={{
        ...navColorVars.layout,
        '--layout-transition-easing': 'linear',
        '--layout-transition-duration': '120ms',
        '--layout-nav-mini-width': '88px',
        '--layout-nav-vertical-width': '300px',
        '--layout-nav-horizontal-height': '64px',
        '--layout-auth-content-width': '600px',
        '--layout-dashboard-content-pt': theme.spacing(1),
        '--layout-dashboard-content-pb': theme.spacing(8),
        '--layout-dashboard-content-px': theme.spacing(5),
      }}
      sx={{
        [`& .${layoutClasses.hasSidebar}`]: {
          [theme.breakpoints.up(layoutQuery)]: {
            transition: theme.transitions.create(['padding-left'], {
              easing: 'var(--layout-transition-easing)',
              duration: 'var(--layout-transition-duration)',
            }),
            pl: isNavMini ? 'var(--layout-nav-mini-width)' : 'var(--layout-nav-vertical-width)',
          },
        },
        ...sx,
      }}
    >
      <Main layoutQuery={layoutQuery}>
        <Content layoutQuery={layoutQuery}>{children}</Content>
      </Main>
    </LayoutSection>
  );
}
