import type { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '@reduxjs/toolkit';

export type UserManagementState = {
  loading: boolean;
  error: string | null;
};

interface RemoveSubmissionPayload {
  userId: string;
  submissionId: string;
  reasonId: string;
  brandId: string;
}

interface UserIdPayload {
  userId: string;
}

const initialState: UserManagementState = {
  loading: false,
  error: null,
};

export const userManagementSlice = createSlice({
  name: 'user-management',
  initialState,
  reducers: {
    removeSubmissionRequest: (state, action: PayloadAction<RemoveSubmissionPayload>) => {
      state.loading = true;
      state.error = null;
    },
    removeSubmissionSuccess: (state) => {
      state.loading = false;
    },
    removeSubmissionFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    deactivateUserRequest: (state, action: PayloadAction<UserIdPayload>) => {
      state.loading = true;
      state.error = null;
    },
    deactivateUserSuccess: (state) => {
      state.loading = false;
    },
    deactivateUserFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    activateUserRequest: (state, action: PayloadAction<UserIdPayload>) => {
      state.loading = true;
      state.error = null;
    },
    activateUserSuccess: (state) => {
      state.loading = false;
    },
    activateUserFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  removeSubmissionRequest,
  removeSubmissionSuccess,
  removeSubmissionFailure,
  deactivateUserRequest,
  deactivateUserSuccess,
  deactivateUserFailure,
  activateUserRequest,
  activateUserSuccess,
  activateUserFailure,
} = userManagementSlice.actions;

export default userManagementSlice.reducer;
