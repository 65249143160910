import type { PayloadAction } from '@reduxjs/toolkit';
import type { Contest } from 'src/interfaces/contest';

import { createSlice } from '@reduxjs/toolkit';

export type ContestState = {
  loading: boolean;
  error: string | null;
  contest: Contest | null;
};

const initialState: ContestState = {
  loading: false,
  error: null,
  contest: null,
};

export const contestSlice = createSlice({
  name: 'contest',
  initialState,
  reducers: {
    setContest: (state, action: PayloadAction<Contest | null>) => {
      state.contest = action.payload;
    },
    triggerFetchContestRequest: (state, action: PayloadAction<string>) => {
      state.loading = true;
      state.error = null;
    },
    triggerFetchContestSuccess: (state, action: PayloadAction<Contest>) => {
      state.loading = false;
      state.contest = action.payload;
    },
    triggerFetchContestFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  setContest,
  triggerFetchContestRequest,
  triggerFetchContestSuccess,
  triggerFetchContestFailure,
} = contestSlice.actions;

export default contestSlice.reducer;
