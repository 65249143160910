import type { PayloadAction } from '@reduxjs/toolkit';

import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { put, call, takeLatest } from 'redux-saga/effects';

import {
  setStatusToDoneRequest,
  setStatusToDoneSuccess,
  setStatusToDoneFailure,
  updateContestStatusRequest,
  updateContestStatusSuccess,
  updateContestStatusFailure,
} from '../reducers/changeToReadySlice';

import type { UpdateContestStatusPayload } from '../reducers/changeToReadySlice';

export async function getFirebaseToken() {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) {
    throw new Error('No user is signed in');
  }
  return user.getIdToken(true);
}

function* updateContestStatusSaga(
  action: PayloadAction<UpdateContestStatusPayload>
): Generator<any, void, any> {
  try {
    const { contestId } = action.payload;
    const idToken = yield call(getFirebaseToken);

    yield call(
      axios.post,
      `${import.meta.env.VITE_BASE_URL}/admin/start-contest`,
      {
        contestId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    yield put(updateContestStatusSuccess());
  } catch (error: any) {
    yield put(updateContestStatusFailure(error.message || 'Failed to update contest status'));
  }
}

function* setContestToDone(
  action: PayloadAction<UpdateContestStatusPayload>
): Generator<any, void, any> {
  try {
    const { contestId } = action.payload;
    const idToken = yield call(getFirebaseToken);

    yield call(
      axios.post,
      `${import.meta.env.VITE_BASE_URL}/admin/mark-as-done`,
      {
        contestId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    yield put(setStatusToDoneSuccess());
  } catch (error: any) {
    yield put(setStatusToDoneFailure(error.message || 'Failed to update contest status'));
  }
}

export function* watchContestStatusSaga() {
  yield takeLatest(updateContestStatusRequest.type, updateContestStatusSaga);
  yield takeLatest(setStatusToDoneRequest.type, setContestToDone);
}
