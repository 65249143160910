import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { put, call, takeLatest } from 'redux-saga/effects';

import { updateSelectedUser } from '../reducers/selectedUser';
import {
  activateUserRequest,
  activateUserSuccess,
  activateUserFailure,
  deactivateUserRequest,
  deactivateUserSuccess,
  deactivateUserFailure,
  removeSubmissionRequest,
  removeSubmissionSuccess,
  removeSubmissionFailure,
} from '../reducers/userManagementSlice';

interface RemoveSubmissionPayload {
  userId: string;
  submissionId: string;
  reasonId: string;
  brandId: string;
}

interface DeactivateUserPayload {
  userId: string;
}

interface ActivateUserPayload {
  userId: string;
}

async function getFirebaseToken() {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) {
    throw new Error('No user is signed in');
  }
  return user.getIdToken(true);
}

function* removeSubmissionSaga(
  action: ReturnType<typeof removeSubmissionRequest>
): Generator<any, void, any> {
  const payload = action.payload as RemoveSubmissionPayload | undefined;
  if (!payload) {
    yield put(removeSubmissionFailure('Invalid payload'));
    return;
  }

  const { userId, submissionId, reasonId, brandId } = payload;

  try {
    const idToken = yield call(getFirebaseToken);
    yield call(
      axios.post,
      `${import.meta.env.VITE_BASE_URL}/admin/remove-user-submission`,
      {
        userId,
        submissionId,
        reasonId,
        brandId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    yield put(removeSubmissionSuccess());
  } catch (error: any) {
    yield put(removeSubmissionFailure(error.message || 'Failed to remove submission'));
  }
}

function* deactivateUserSaga(
  action: ReturnType<typeof deactivateUserRequest>
): Generator<any, void, any> {
  const payload = action.payload as DeactivateUserPayload | undefined;
  if (!payload) {
    yield put(deactivateUserFailure('Invalid payload'));
    return;
  }

  const { userId } = payload;

  try {
    const idToken = yield call(getFirebaseToken);
    yield call(
      axios.post,
      `${import.meta.env.VITE_BASE_URL}/admin/block-user-account`,
      {
        userId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    yield put(deactivateUserSuccess());
    yield put(updateSelectedUser({ user_status: 'Inactive' }));
  } catch (error: any) {
    yield put(deactivateUserFailure(error.message || 'Failed to deactivate user'));
  }
}

function* activateUserSaga(
  action: ReturnType<typeof activateUserRequest>
): Generator<any, void, any> {
  const payload = action.payload as ActivateUserPayload | undefined;
  if (!payload) {
    yield put(activateUserFailure('Invalid payload'));
    return;
  }

  const { userId } = payload;

  try {
    const idToken = yield call(getFirebaseToken);
    yield call(
      axios.post,
      `${import.meta.env.VITE_BASE_URL}/admin/unblock-user-account`,
      {
        userId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    yield put(activateUserSuccess());
    yield put(updateSelectedUser({ user_status: 'Active' }));
  } catch (error: any) {
    yield put(activateUserFailure(error.message || 'Failed to activate user'));
  }
}

export function* watchUserManagementSaga() {
  yield takeLatest(removeSubmissionRequest.type, removeSubmissionSaga);
  yield takeLatest(deactivateUserRequest.type, deactivateUserSaga);
  yield takeLatest(activateUserRequest.type, activateUserSaga);
}
