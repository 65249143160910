import type { BoxProps } from '@mui/material/Box';
import type { Breakpoint } from '@mui/material/styles';

import { useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';

import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useUser } from 'src/hooks/use-user';

import { AUTH } from 'src/lib/firebase';
import { layoutClasses } from 'src/layouts/classes';
// ----------------------------------------------------------------------

type MainProps = BoxProps & {
  layoutQuery: Breakpoint;
};

export function Main({ sx, children, layoutQuery, ...other }: MainProps) {
  const theme = useTheme();

  return (
    <Box
      component="main"
      className={layoutClasses.main}
      sx={{
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.neutral,
        [theme.breakpoints.up(layoutQuery)]: {
          flexDirection: 'row',
        },
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  );
}

// ----------------------------------------------------------------------

const getPageTypeText = (path: string, user: string | null): string | null => {
  switch (path) {
    case '/auth/brand-topics':
      return 'Select one or more topics that best relate to your brand';
    case '/auth/finish-profile':
      return user ? `Welcome ${user}!` : null;
    default:
      return null;
  }
};

const PageHeader = ({ text }: { text: string }) => {
  const theme = useTheme();

  return (
    <Box
      className={layoutClasses.header}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        mb: theme.spacing(4),
        mt: theme.spacing(7),
        fontFamily: theme.typography.fontFamily,
        fontStyle: 'normal',
        fontWeight: theme.typography.fontWeightBold,
        fontSize: '32px',
        lineHeight: '48px',
        textAlign: 'center',
        color: theme.palette.text.primary,
        maxWidth: '546px',
      }}
    >
      {text}
    </Box>
  );
};

// ----------------------------------------------------------------------

export function Content({ sx, children, layoutQuery, ...other }: MainProps) {
  const theme = useTheme();
  const [userName, setUserName] = useState<string | null>(null);
  const { user, fetchUser } = useUser();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(AUTH, (userProfile) => {
      if (userProfile) {
        fetchUser(userProfile.uid);
      } else {
        console.error('User is not logged in');
      }
    });

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      setUserName(user.display_name);
    }
  }, [user]);

  const currentPath = window.location.pathname;
  const pageHeaderText = getPageTypeText(currentPath, userName);
  let bookCall: string | null = null;

  if (currentPath === '/auth/create-subscription') {
    bookCall = 'Not sure yet? Book a free call with Steven';
  }

  return (
    <Box
      className={layoutClasses.content}
      sx={{
        display: 'flex',
        flex: '1 1 auto',
        alignItems: 'center',
        flexDirection: 'column',
        p: theme.spacing(3, 2, 10, 2),
        [theme.breakpoints.up(layoutQuery)]: {
          justifyContent: 'center',
          p: theme.spacing(9, 2, 10, 2),
        },
        ...sx,
      }}
      {...other}
    >
      {pageHeaderText && <PageHeader text={pageHeaderText} />}

      <Box
        sx={{
          width: 1,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '16px',
          border: (borderTheme) => `solid 1px ${borderTheme.palette.divider}`,
          px: 3,
          py: 3,
          backgroundColor: theme.palette.background.default,
          maxWidth: 'var(--layout-auth-content-width)',
        }}
      >
        {children}
      </Box>
      {bookCall && (
        <a
          href="https://calendly.com/steven-postedapp/posted-app-intro"
          target="_blank"
          style={{
            textDecoration: 'none',
            color: theme.palette.text.primary,
            paddingTop: '27px',
          }}
          rel="noreferrer"
        >
          <Typography variant="subtitle2">{bookCall}</Typography>
        </a>
      )}
    </Box>
  );
}
