// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  faqs: '/faqs',
  minimalStore: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    amplify: {
      signIn: `${ROOTS.AUTH}/amplify/sign-in`,
      verify: `${ROOTS.AUTH}/amplify/verify`,
      signUp: `${ROOTS.AUTH}/amplify/sign-up`,
      updatePassword: `${ROOTS.AUTH}/amplify/update-password`,
      resetPassword: `${ROOTS.AUTH}/amplify/reset-password`,
    },
    jwt: {
      signIn: `${ROOTS.AUTH}/jwt/sign-in`,
      signUp: `${ROOTS.AUTH}/jwt/sign-up`,
    },
    firebase: {
      signIn: `${ROOTS.AUTH}/sign-in`,
      verify: `${ROOTS.AUTH}/verify`,
      signUp: `${ROOTS.AUTH}/sign-up`,
      finishProfile: `${ROOTS.AUTH}/finish-profile`,
      brandTopic: `${ROOTS.AUTH}/brand-topics`,
      resetPassword: `${ROOTS.AUTH}/reset-password`,
      createSubscription: `${ROOTS.AUTH}/create-subscription`,
    },
    auth0: {
      signIn: `${ROOTS.AUTH}/auth0/sign-in`,
    },
    supabase: {
      signIn: `${ROOTS.AUTH}/supabase/sign-in`,
      verify: `${ROOTS.AUTH}/supabase/verify`,
      signUp: `${ROOTS.AUTH}/supabase/sign-up`,
      updatePassword: `${ROOTS.AUTH}/supabase/update-password`,
      resetPassword: `${ROOTS.AUTH}/supabase/reset-password`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: `${ROOTS.DASHBOARD}/contests`,
    myBrand: `${ROOTS.DASHBOARD}/my-brand`,
    settings: `${ROOTS.DASHBOARD}/settings`,
    brands: `${ROOTS.DASHBOARD}/brands`,
    payouts: `${ROOTS.DASHBOARD}/payouts`,
    juicedMembers: `${ROOTS.DASHBOARD}/juiced-members`,
    userManagement: `${ROOTS.DASHBOARD}/user-management`,
    contestDetails: `${ROOTS.DASHBOARD}/contest/:contestId`,
    createNewContest: `${ROOTS.DASHBOARD}/contest/create-new-contest`,
    viewAllContests: `${ROOTS.DASHBOARD}/contest/view-all-contests`,
    brandManagement: `${ROOTS.DASHBOARD}/brand-management`,
  },
};
