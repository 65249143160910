import type { Brand } from 'src/interfaces/brand';
import type { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '@reduxjs/toolkit';

export type BrandState = {
  loading: boolean;
  error: string | null;
  brand: Brand | null;
};

const initialState: BrandState = {
  loading: false,
  error: null,
  brand: null,
};

export const brandSlice = createSlice({
  name: 'brand',
  initialState,
  reducers: {
    setBrand: (state, action: PayloadAction<Brand | null>) => {
      state.brand = action.payload;
    },
    updateBrand: (state, action: PayloadAction<Partial<Brand>>) => {
      if (state.brand) {
        state.brand = { ...state.brand, ...action.payload };
      }
    },
    triggerBrandBioVerificationRequest: (state, action: PayloadAction<{ username: string }>) => {
      state.loading = true;
      state.error = null;
    },
    triggerBrandBioVerificationSuccess: (state) => {
      state.loading = false;
    },
    triggerBrandBioVerificationFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  triggerBrandBioVerificationRequest,
  triggerBrandBioVerificationSuccess,
  triggerBrandBioVerificationFailure,
  setBrand,
  updateBrand,
} = brandSlice.actions;

export default brandSlice.reducer;
