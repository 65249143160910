import type { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '@reduxjs/toolkit';

export interface ContestStatusState {
  loading: boolean;
  error: string | null;
  success: boolean;
}

const initialState: ContestStatusState = {
  loading: false,
  error: null,
  success: false,
};

export interface UpdateContestStatusPayload {
  contestId: string;
}

export const contestStatusSlice = createSlice({
  name: 'contestStatus',
  initialState,
  reducers: {
    updateContestStatusRequest: (state, action: PayloadAction<UpdateContestStatusPayload>) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    },
    updateContestStatusSuccess: (state) => {
      state.loading = false;
      state.success = true;
      state.error = null;
    },
    updateContestStatusFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
    setStatusToDoneRequest: (state, action: PayloadAction<UpdateContestStatusPayload>) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    },
    setStatusToDoneSuccess: (state) => {
      state.loading = false;
      state.success = true;
      state.error = null;
    },
    setStatusToDoneFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
  },
});

export const {
  updateContestStatusRequest,
  updateContestStatusSuccess,
  updateContestStatusFailure,
  setStatusToDoneRequest,
  setStatusToDoneSuccess,
  setStatusToDoneFailure,
} = contestStatusSlice.actions;

export default contestStatusSlice.reducer;
