import type { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '@reduxjs/toolkit';

export type CreateLeadState = {
  loading: boolean;
  error: string | null;
};

const initialState: CreateLeadState = {
  loading: false,
  error: null,
};

export const createLeadSlice = createSlice({
  name: 'create-lead',
  initialState,
  reducers: {
    triggerCreateLeadRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    triggerCreateLeadSuccess: (state) => {
      state.loading = false;
      state.error = null;
    },
    triggerCreateLeadFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { triggerCreateLeadRequest, triggerCreateLeadSuccess, triggerCreateLeadFailure } =
  createLeadSlice.actions;

export default createLeadSlice.reducer;
