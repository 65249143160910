import type { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '@reduxjs/toolkit';

export type PaymentState = {
  loading: boolean;
  error: string | null;
  contestId: string | null;
  amount: number | null;
};

const initialState = {
  loading: false,
  error: null,
  contestId: null,
  amount: null,
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    paymentRequest: (
      state,
      action: PayloadAction<{
        amount: number;
        contestId: string;
      }>
    ) => {
      state.loading = true;
      state.error = null;
    },
    paymentSuccess: (state, action) => {
      state.loading = false;
      state.amount = action.payload;
    },
    paymentFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { paymentRequest, paymentSuccess, paymentFailure } = paymentSlice.actions;

export default paymentSlice.reducer;
