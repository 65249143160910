import type { User } from 'src/interfaces/user';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { DocumentData } from 'firebase/firestore';

import { doc, getDoc } from 'firebase/firestore';
import { put, call, takeLatest } from 'redux-saga/effects';

import { FIRESTORE } from 'src/lib/firebase';

import {
  triggerFetchUserRequest,
  triggerFetchUserSuccess,
  triggerFetchUserFailure,
} from '../reducers/userSlice';

function* triggerFetchUserSaga(action: PayloadAction<string>): Generator<any, void, DocumentData> {
  try {
    const userRef = doc(FIRESTORE, 'users', action.payload);
    const userDoc = yield call(getDoc, userRef);

    if (userDoc.exists()) {
      yield put(triggerFetchUserSuccess(userDoc.data() as User));
    } else {
      yield put(triggerFetchUserFailure('User not found in database'));
    }
  } catch (error: any) {
    yield put(triggerFetchUserFailure(error.message || 'Failed to fetch user data'));
  }
}

export function* watchUserSaga() {
  yield takeLatest(triggerFetchUserRequest.type, triggerFetchUserSaga);
}
